<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('close')"
    width="75vw"
    class="dialog"
    scrollable
  >
    <v-card v-if="gameToBePromoted">
      <v-card-title class="text-h5 primary white--text mb-6">
        {{ labels.promote }} {{ gameToBePromoted.name }}
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="6">
            <div>From:</div>
            <v-chip color="primary" class="my-3">
              {{environment.name}}
            </v-chip>
            <div>{{ labels.bev }}</div>
            <v-checkbox
              v-model="promoteBack"
              class="mx-1"
              :label="gameToBePromoted.game_backend_full_version"
            >
            </v-checkbox>
            <div v-if="gameToBePromoted.game_frontend_version">
              <div>{{ labels.fev }}</div>
              <v-checkbox
                v-model="promoteFront"
                :label="gameToBePromoted.game_frontend_version"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="6">
            <div>To:</div>
            <v-radio-group
              v-model="promoteTo"
              mandatory
            >
              <v-radio
                v-for="(prom, index) in environment.allowed_promotions"
                :key="index"
                :label="prom.name"
                :value="prom"
              >
              </v-radio>
            </v-radio-group>

            <v-radio-group
              v-model="promotionOverride"
              v-if="promoteTo"
              mandatory
            >
              <v-radio
                :label="labels.newInstance"
                :value="null"
              >
              </v-radio>
              <v-progress-circular
                v-if="loading"
                color="primary"
                indeterminate
              >
              </v-progress-circular>
              <v-radio
                v-else
                v-for="(target, index) in promotionTargets"
                :key="index"
                :label="overrideLabel"
                :value="target"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          {{ labels.close }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="promote"
        >
          {{ labels.promote }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get, isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'promotionDialog',
  props: {
    gameToBePromoted: {
      type: Array[Object],
      required: false,
      default: () => [{}],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
  },
  data: () => ({
    labels,
    promoteBack: true,
    promoteFront: true,
    promotionOverride: null,
    promotionTargets: null,
    promoteTo: null,
  }),
  methods: {
    ...mapActions({
      fetchGameInstances: 'fetch/fetchGameInstances',
    }),
    overrideLabel(target) {
      return (
        `Override: ${target.game_backend_version} (FE ${target.game_frontend_version}`
      );
    },
    promote() {
      if (!this.promoteBack && !this.promoteFront) return;
      const body = {
        environment: this.promoteTo.name,
        promoteBack: this.promoteBack,
        promoteFront: this.promoteFront,
        senderGameInstance: this.gameToBePromoted.id,
        ...(
          !isEmpty(this.promotionOverride)
          && { receiverGameInstance: this.promotionOverride.id }
        ),
      };
      this.$emit('promote', body);
    },
  },
  watch: {
    // gameToBePromoted() {
    //   this.promoteTo = false;
    // },
    promoteTo: {
      /* eslint no-unused-vars: ["off"] */
      handler: function handler(val, _) {
        if (!val) return;
        const params = {
          environment_id: get(this.promoteTo, 'id'),
          image: get(this.gameToBePromoted, 'image'),
        };
        this.fetchGameInstances({
          params,
          returnValue: true,
        }).then((result) => { this.promotionTargets = result; });
        // this.fetchData(
        //   '/v2/gameinstances',
        // ).then((response) => {
        //   this.promotionTargets = response.results;
        //   this.loading = false;
        // });
      },
    },
  },
};
</script>

<style scoped>
</style>
